import React, { Component } from 'react';
import ListingHeader from '../Common/ListingHeader';
import { getWorkflowData } from '../Common/Constant';
import Calculator from '../Common/Calculator';

class WorkflowDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            workFlowData: {},
            PrLogicArray:[]
        }

    }
    componentDidMount = () => {
        const {
            match: { params },
        } = this.props;

        this.getWorkFlowData(params.index);
    }

    getWorkFlowData = (id) => {
        let data = JSON.parse(getWorkflowData());
        let workFlow;
        data.forEach(element => {
            if (element.Id.toString() === id.toString()) {
                workFlow = element
            }
        });

        this.setState({
            workFlowData: workFlow
        });
        this.splitPrLogicData(workFlow);
    }
    splitPrLogicData = (workFlow) => {
        var RawData = workFlow.PrLogic;        
        this.setState({
            PrLogicArray: RawData.split(', ')
        })
    }
    render() {
        return (
            <>

                <ListingHeader></ListingHeader>
                <section className="box-shadow-white-box-section grey-bg py-2" id="partner" style={{ backgroundColor: "#f5f2f2" }}>
                </section>
                {/* <section className="box-shadow-white-box-section grey-bg py-2" id="partner" style={{ backgroundColor: "#f5f2f2" }}>
                    <div className="container-fluid" >
                        <div className="box-shadow-white-box-a ">
                            <div className="">
                                <div className="row flex-reversea">
                                    <div className="col-sm-12 col-lg-7 col-md-12 col-12">
                                        <div className="pickup-location-box">
                                            <h5 className="font-bold mb-3 mt-2 ">{this.state.workFlowData.Title + " (" + this.state.workFlowData.SingleVBundle + ")"}</h5>
                                            <div className="pickup-location-flex">

                                                <div className="pickup-location-flex-content">
                                                    <h6 className="font-bold mb-3 mt-2 ">
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.workFlowData.ShortDescription }} />

                                                    </h6>
                                                    Contributor-
                                                    <span>
                                                       {this.state.workFlowData.Contributor}
                                                    </span><br />
                                                    Category-<span>
                                                       {this.state.workFlowData.Category}
                                                    </span> <br />
                                                    Priority-<span>
                                                       {this.state.workFlowData.Priority}
                                                    </span>   <br />    
                                                    Go Live Date-<span>
                                                       {this.state.workFlowData.LiveDate}
                                                    </span>                                           
                                                </div>
                                            </div>                                          
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-5 col-md-12 col-12">
                                        <img src={this.state.workFlowData.ContributorURL} alt="" className="img-fluid rounded-xl"  /><br/>
                                        <img src={this.state.workFlowData.ConsumerURL} alt="" className="img-fluid rounded-xl"  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="box-shadow-white-box-section grey-bg pb-4" style={{ backgroundColor: "#f5f2f2" }}>
                    <div className="container-fluid" >

                        <div className="row" >
                            <div className="col-lg-7">
                                <div className="box-shadow-white-box" >
                                    <h5 className="font-bold mb-3 mt-2 ">{this.state.workFlowData.Title}</h5>
                                    <div className="pickup-location-flex">

                                        <div className="pickup-location-flex-content">
                                            <h6 className="font-bold mb-3 mt-2 ">
                                                <div dangerouslySetInnerHTML={{ __html: this.state.workFlowData.ShortDescription }} />

                                            </h6>
                                            {/* Contributor-
                                            <span>
                                                {this.state.workFlowData.Contributor}
                                            </span><br /> */}
                                            Category-<span>
                                                {this.state.workFlowData.Category}
                                            </span> <br />
                                            Priority-<span>
                                                {this.state.workFlowData.Priority}
                                            </span>   <br />
                                            Go Live Date-<span>
                                                {this.state.workFlowData.LiveDate}
                                            </span>
                                        </div>
                                    </div>

                                </div>

                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Market Challenge</h5>

                                    <div dangerouslySetInnerHTML={{ __html: this.state.workFlowData.MarketChallenge }} />

                                </div>

                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Description</h5>

                                    <div dangerouslySetInnerHTML={{ __html: this.state.workFlowData.Description }} />

                                </div>
                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Size Of Opportunity</h5>

                                    <div dangerouslySetInnerHTML={{ __html: this.state.workFlowData.SizeOfOpportunity }} />

                                </div>
                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Threats of Adoption</h5>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.workFlowData.Threats }} />
                                </div>

                            </div>

                            <div className="col-lg-5" >
                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Creators</h5>
                                    <img src={this.state.workFlowData.ContributorURL} alt="" className="img-fluid rounded-xl" /><br />

                                </div>
                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Consumers</h5>
                                    <img src={this.state.workFlowData.ConsumerURL} alt="" className="img-fluid rounded-xl" />

                                </div>
                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Financial Model</h5>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.workFlowData.FinancialModel }} />

                                </div>
                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Calculator</h5>
                                    <br />
                                    {
                                        this.state.workFlowData.TypeOfOpportunity == "Premium Calculator" ?
                                            <Calculator row1={1500} row2={10} row3={50} row4={1.5} header="Premium Flow" row1Text="Avg. GWP Per Transaction" row2Text="# of Partners / Agencies"
                                                row3Text="# of Transactions Month/Per Partner" row4Text="Exchange Fee " ></Calculator>
                                            :
                                            this.state.workFlowData.TypeOfOpportunity == "Data Calculator" ?
                                                <Calculator row1={10} row2={50} row3={500} row4={15.00} header="Data Solutions" row1Text="Avg Price Per Data Call to month" row2Text="# of Data Exchange Consumption Partners"
                                                    row3Text="# of Monthly Data Transactions Per Partner" row4Text="Data Platform Transaction Fee" ></Calculator>
                                                :
                                                <Calculator row1={10} row2={50} row3={500} row4={15.00} header="Technology / App Solutions / Workflows" row1Text="Avg Price Per Tech Solution" row2Text="# of TechExchange Consumption Partners"
                                                    row3Text="# of Monthly Tech Transactions Per Partner" row4Text="Tech Platform Transaction Fee" ></Calculator>

                                    }
                                </div>
                                <div className="box-shadow-white-box" >
                                    <h5 className="font-weight-bold">Prioritization Logic</h5>
                                    {/* <ul class="check-box-listing ">
                                        <li><img src={blank} alt="" className="img-fluid rounded-xl" style={{ width: "13px" }} /><span style={{ paddingLeft: "10px" }}>Immediate Demand</span></li>
                                        <li><img src={blank} alt="" className="img-fluid rounded-xl" style={{ width: "13px" }} /><span style={{ paddingLeft: "10px" }}>Ease of Development</span></li>
                                        <li><img src={check} alt="" className="img-fluid rounded-xl" style={{ width: "13px" }} /><span style={{ paddingLeft: "10px" }}>Revenue Potential </span></li>
                                        <li><img src={blank} alt="" className="img-fluid rounded-xl" style={{ width: "13px" }} /><span style={{ paddingLeft: "10px" }}>Partner Impact </span></li>
                                    </ul> */}
                                   {
                                       this.state.PrLogicArray.map(item=>{
                                           return(
                                               <ul>
                                            <li>{item}</li>
                                            </ul>
                                           );
                                       })
                                   }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </>
        );
    }
}

export default WorkflowDetail;