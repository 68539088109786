import React, { Component } from 'react'
import ListingHeader from '../Common/ListingHeader'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getWorkflowData } from '../Common/Constant';
import { Button } from 'primereact/button';

export default class WorkflowListing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workFlows: []
        }
    }


    componentDidMount() {
        let data = JSON.parse(getWorkflowData());
        this.setState({
            workFlows: data
        });
    }

    onDetailsClick = (Id) => {
        this.props.history.push(`/WorkflowDetail/${Id}`)
    }
    onKGClick = (Id) => {
        // this.props.history.push(`/WorkflowDetail/${Id}`)
        window.open(`/graph`,"_blank");
    }


    detailsTemplate = (rowData) => {
        return <Button className="p-button-primary"

            onClick={() => this.onDetailsClick(rowData.Id)}

        >Details</Button>;
    }

    creatorTemplate = (rowData) => {
        return <img src={rowData.ContributorURL} style={{ width: "150px" }}
        />;
    }
    consumerTemplate = (rowData) => {
        return <img src={rowData.ConsumerURL} style={{ width: "150px" }}
        />;
    }

    graphTemplate = (rowData) => {
         return <a href="#" onClick={() => this.onKGClick(rowData.Id)}>KG</a>
       
    }

    nameTemplate = (rowData) => {
        return <a href="#" onClick={() => this.onDetailsClick(rowData.Id)}>{rowData.Title}</a>;
    }

    htmlTemplate = (rowData) => {
        return <div dangerouslySetInnerHTML={{ __html: rowData.ShortDescription }} />;
    }


    render() {

        return (
            <>
                <ListingHeader></ListingHeader>

                <section className="box-shadow-white-box-section grey-bg py-2" id="partner" style={{ backgroundColor: "#f5f2f2" }}>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid" >
                                <div className="row" >
                                    <div className="col-lg-12">
                                        <div className="box-shadow-white-box" style={{ textAlign: "center" }}>
                                            <h3 className="font-bold mb-3 mt-2 ">Workflows</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-lg-12">
                                        <div className="box-shadow-white-box-a ">
                                            <DataTable value={this.state.workFlows}>
                                                {/* <Column style={{ width: "7%" }} field="Priority" header="Priority" sortable></Column> */}
                                                <Column style={{ width: "25%" }} header="Name" body={this.nameTemplate} sortable></Column>
                                                <Column style={{ width: "40%" }} field="ShortDescription" header="Description" body={this.htmlTemplate}></Column>
                                                <Column header="Create Party" body={this.creatorTemplate}></Column>
                                                <Column header="Consumption Party" body={this.consumerTemplate}></Column>
                                                {/* <Column field="LiveDate" header="Go-Live Date" sortable></Column> */}
                                                {/* <Column header="KG" body={this.graphTemplate}></Column> */}
                                                {/* <Column header="Details" body={this.detailsTemplate}></Column> */}
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }
}
