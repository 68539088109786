export function getWorkflowData() {
  return `
  [
    {
       "Id":1,
       "Priority":"Complete",
       "Title":"Policy Tracking",
       "ShortDescription":"Agents are able to monitor all the policies of their clients in a single, central place. Cancellations, claims, endorsements, renewals are all tracked daily and the agents are notified immediately of any changes, allowing them to assist their clients and provide assistance when most needed. ",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS, Hawksoft",
       "Contributor":"Adapt, CanopyConnect",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/1_Contributor.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/1_Cosumer.png"
    },
    {
       "Id":2,
       "Priority":"Complete",
       "Title":"Business Classification",
       "ShortDescription":"Agent, broker or underwriter provide the business name & address of the insured and are immediately retured with multiple relevant classification codes, website links that support these classifications and a confidence score of the underlying model.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"Heffernan, Insureon, Appalachian, Vertafore",
       "Contributor":"Relativity6, NeuralMetrics, Friss, Planck, Verisk",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/2_Contributor.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/2_Consumer.png"
    },
    {
       "Id":3,
       "Priority":"Complete",
       "Title":"Quote and Bind",
       "ShortDescription":"Aggregators of carrier integrations, each with the ability to provide quote-bind services through dozens of carriers, are brought into a single location on the agent AMS. Integrating these into the AMS allows the agent to quote their client, across hundreds of carriers, right from their dashboard without having to re-key information already in their AMS. Agents are also able to get all their renewals automatically quoted prior to renewals and flagged accordingly.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"Hawksoft, Agentero, Certificial",
       "Contributor":"Briza, Talage, BoldPenguin, Relay, IBYND",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/3_Contributor.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/3_Consumer.png"
    },
    {
       "Id":4,
       "Priority":"Complete",
       "Title":"Revive dead leads",
       "ShortDescription":"Agents upload their list of leads that they have lost contact with or that never materialized into clients. The list is sent to the service provider which attempts to contact the leads via SMS messages using natural language models, and get the lead on the phone with the agent.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS",
       "Contributor":"Aktify",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/Aktify.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/BTIS.png"
    },
    {
       "Id":5,
       "Priority":"Complete",
       "Title":"Business General Insights",
       "ShortDescription":"Agent, broker or underwriter provide the business name & address of the insured and are immediately retured with all the general properties of the business, these are required for any policy application. These fields can be directly populated into the quote-bind workflow to provide a one-click experience for quoting and binding.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"Heffernan, Appalachian, Vertafore, CommercialInsurance.Net",
       "Contributor":"Relativity6, NeuralMetrics, Friss, Planck, Convr, Verisk",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/2_Contributor.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/5_Consumer.png"
    },
    {
       "Id":6,
       "Priority":"Complete",
       "Title":"Digital Forms Submissions",
       "ShortDescription":"Agents can now search through over 10,000 fully digitized insurance forms and send their clients or prospects a link to fill almost any form online. Filled forms can be directly integrated into the quote-bind workflow to provide a fully digital experience for quoting and binding.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS",
       "Contributor":"BrokerBuddha",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/BB.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/BTIS.png"
    },
    {
       "Id":7,
       "Priority":"Complete",
       "Title":"Property Insights and Location Risk Scores",
       "ShortDescription":"Agent, broker or underwriter provide the business name & address of the insured and are immediately retured with all the property insights of the business as well as accurate risk scodes of the business. Agent is able to more accurately and quickly provide the client the necessary quotes for their business.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"Heffernan, Appalachian, Vertafore, CommercialInsurance.Net",
       "Contributor":"Fenris, Planck, Urbanico, Convr, Verisk",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/7_Contributor.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/5_Consumer.png"
    },
    {
       "Id":8,
       "Priority":"Complete",
       "Title":"Digital COIs",
       "ShortDescription":"Certificates of insurance issuance and compliance tracking and management of hundreds of clients with thousands of cert holders becomes fully automated over web or phone. Integration with the AMS additionally allows the agent to seamlessly add new policies as well as update back any policy changes to meet with new compliance requirements.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS,Hawksoft,Agentero,Suracy",
       "Contributor":"Certificial, TrustLayer",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/8.Contributor.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/8_Consumer.png"
    },
    {
       "Id":9,
       "Priority":"Complete",
       "Title":"Digital User Story",
       "ShortDescription":"Create for your customers a fully digital journey, through various interconnected forms, signatures,  payments and more. Each step of the journey connects to various additional workflows for data enrichment and prefill of forms, quote and bind, rates comparisons, payment processors and more.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS,Hawksoft,Agentero",
       "Contributor":"EasySend",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/ES.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/8_Consumer.png"
    },
    {
       "Id":10,
       "Priority":"Complete",
       "Title":"Payroll Prefill and Tracking",
       "ShortDescription":"Filling payroll and employee information on applications such as Workers Comp quote become as simple as one-click. Agents can provide their prospects a secure link to log into their payroll provider and enable an automatic pull of all the necessary information directly from that provider into the application in front of the agent. Carriers recieve the most accurate payroll information for most accurate and immediate quoting.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"AmTrust, Insurmedics, CommercialInsurance.Net",
       "Contributor":"Finch, Merge.Dev",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/10_Contributor.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/10_Consumer.png"
    },
    {
       "Id":11,
       "Priority":"Complete",
       "Title":"Surplus Lines Tax Calculator",
       "ShortDescription":"Clients specializing in surplus lines are required to file convoluted tax forms for their clients. The integration of a unique tax calculator specificaly designed for surplus lines policies with seamless integration into the AMS to prefill all necessary information, preventing human errors and simplifying this tedious task.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"Relay, Hawksoft, Agentero",
       "Contributor":"Inschipher",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/11Contributor.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/11_Consumer.png"
    },
    {
       "Id":12,
       "Priority":"Complete",
       "Title":"Policy Management",
       "ShortDescription":"Agents can now offload their low-premium clients to third party and dedicate more time to higher-premium policies that require more expertise. The integration directly into the AMS enables agents to seamlessly transfer their clients to and from the third party management, as well as get real-time policy updates back into their AMS.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"Hawksoft, BTIS, Agentero",
       "Contributor":"Layr",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/Layer.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/8_Consumer.png"
    },
    {
       "Id":13,
       "Priority":"Complete",
       "Title":"Events-Triggered Leads Generation",
       "ShortDescription":"Agents are able to receive fresh leads every week, which are selected based on an event that has just been identified at the business. These leads are tailored to the agents appetite, requirements and specialty.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS, Agentero",
       "Contributor":"Leadgence",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/13_Contributor.jpg",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/13_Consumer.png"
    },
    {
       "Id":14,
       "Priority":"Complete",
       "Title":"Tailored Mail Pieces",
       "ShortDescription":"Agents can automatically generate and send letters and greeting cards to all or a selected subset of their clients or leads. The contacts can be directly pulled from their AMS through an additional workflow integration.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS, Hawksoft, Agentero",
       "Contributor":"Lob",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/LOB.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/8_Consumer.png"
    },
    {
       "Id":15,
       "Priority":"Complete",
       "Title":"Fraudulent Form Submission Identification",
       "ShortDescription":"Any submission of the agency or carrier online forms is immediately flagged when an attempt to fill the forms is not done by a human. Alerts can then go off to the owners and forms can be filtered from clogging up quoting and submission queues.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"AmTrust, Heffernan",
       "Contributor":"Oxford BioChronometrics",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/ox.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/15_Consumer.png"
    },
    {
       "Id":16,
       "Priority":"Complete",
       "Title":"Interactive Video Communication",
       "ShortDescription":"Agencies, wholesellers, MGAs can now provide their clients any documents or instructions via an interactive video content, to increase customer compliance and retention. The integraton into the customer book of records turns the process into a fully automated experience.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS, Agentero",
       "Contributor":"Precisely",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/Precisely.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/13_Consumer.png"
    },
    {
       "Id":17,
       "Priority":"Complete",
       "Title":"Document Scanning and OCR",
       "ShortDescription":"Scanned policy documents, photos of hand-filled applications and printed forms can be uploaded, scanned, and translated into digital fields. These can in turn be used to automatically prefill applications and provide quotes in a fully automated process.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"BTIS, CommercialInsurance.Net, Appalachian, AmTrust, Tarmika",
       "Contributor":"Sensible",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/sens.jpg",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/11_Consumer.png"
    },
    {
       "Id":18,
       "Priority":"Complete",
       "Title":"File Conversions",
       "ShortDescription":"Automtically convert multiple files to and from legacy AL3 formats to enable extraction and updating back into legacy systems.",
       "MarketChallenge":"",
       "Description":"",
       "SingleVBundle":"",
       "Category":"",
       "Consumer":"Vertafore, Fumico",
       "Contributor":"Winsurtech",
       "FinancialModel":"",
       "TypeOfOpportunity":"",
       "SizeOfOpportunity":"",
       "Threats":"",
       "LaunchConsumptionParty":null,
       "LaunchContributorParty":null,
       "P2Consumption":null,
       "P2Contributor":null,
       "LiveDate":"08/11/2021",
       "LogoURL":null,
       "PrLogic":"",
       "ContributorURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/Winsurtech.png",
       "ConsumerURL":"https://d10j1ol16dpact.cloudfront.net/workflowdata/logo/18_Consumer.png"
    }
 ]`
}

