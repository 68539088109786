import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import logo from '../assets/images/GIG Logo-03.png';
import {  withRouter } from 'react-router-dom';
// import {  AmplifySignOut } from '@aws-amplify/ui-react';
// import Auth from '@aws-amplify/auth';

class ListingHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SideBarVal: false,
            showStickyHeader:false
        }
    }

    componentDidMount = () => {
      //window.addEventListener('scroll',this.handleScroll,true);
    }
    // handleScroll = ()=>{
    //     let scrollY = window.scrollY;
    //     if(scrollY >150){
    //         this.setState({showStickyHeader:true});
    //     }
    //     else{
    //         this.setState({showStickyHeader:false});
    //     }
    // }
     
    onDashboardClicked = () => {
        this.props.history.push("/");
    }
    onSalesClicked = () => {
        this.props.history.push("/sales");
    }
    onMarketPlaceClicked = () => {
        this.props.history.push("/");
    }
  
   
    render() {

      
        return (
            <div>
                <header className={this.state.showStickyHeader? "header-nav sticky-top header-invert header-sticky" : "header-nav sticky-top header-invert"}>
                    <div className="container-fluid">
                        <div className="navbar navbar-expand-lg navbar-light p-0">
                           
                            <a href="/" className="logo">
                                <img src={logo} width="152" height="39.95" alt="InsuranceGIG" />
                            </a>
                            {/* {
                                 Auth.currentAuthenticatedUser()  != null ?
                                    <div className="collapse navbar-collapse justify-content-left" id="navbarNav" style={{paddingLeft:"25px"}}>
                                        <nav className="navigation-wrap">
                                            
                                        </nav>
                                    </div>
                                    : null
                            } */}

                           
                            {/* <AmplifySignOut></AmplifySignOut> */}
                        </div>
                    </div>
                </header>
                <div>

                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(ListingHeader));