import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';

class Calculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row1: 1500.0,
            row2: 10.0,
            row3: 50.0,
            row4: 1.50,
            multi: 12.0
        }
    }

    componentDidMount() {
        this.setState({
            row1: this.props.row1,
            row2: this.props.row2,
            row3: this.props.row3,
            row4: this.props.row4,
        });
    }

    // row2: this.props.row2,
    // row3: this.props.row3,
    // row4: this.props.row4,
    componentDidUpdate(prevProps) {

        if (this.props.row1 != prevProps.row1) {
            this.setState({
                row1: this.props.row1
            });
        }

        if (this.props.row2 != prevProps.row2) {
            this.setState({
                row2: this.props.row2
            });
        }

        if (this.props.row3 != prevProps.row3) {
            this.setState({
                row3: this.props.row3
            });
        }

        if (this.props.row4 != prevProps.row4) {
            this.setState({
                row4: this.props.row4
            });
        }


    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }



    render() {

        return (
            <div>

                <section className="box-shadow-white-box-section grey-bg py-2" id="partner" style={{ backgroundColor: "#f5f2f2" }}>
                    <div className="row">

                        <div className="col-md-12">
                            <div className="col-sm-12 col-md-12 col-12">
                                <div className="container-fluid" >
                                    <div className="box-shadow-white-box-a ">
                                        <table style={{ width: "100%" }}>
                                            <tr>
                                                <th style={{ paddingLeft: "10px" }}>{this.props.header}</th>
                                                <th></th>

                                            </tr>
                                            <tr>
                                                <td style={{ paddingLeft: "10px" }}>{this.props.row1Text}</td>
                                                <td style={{ width: "150px" }}>$ <input type="text" id="row1" name="row1" value={this.state.row1} onChange={this.onChange} style={{ width: "100px" }}/></td>

                                            </tr>
                                            <tr>
                                                <td style={{ paddingLeft: "10px" }}>{this.props.row2Text}</td>
                                                <td># <input type="text" id="row2" name="row2" value={this.state.row2} onChange={this.onChange} style={{ width: "100px" }}/></td>

                                            </tr>
                                            <tr>
                                                <td style={{ paddingLeft: "10px" }}>{this.props.row3Text}</td>
                                                <td># <input type="text" id="row3" name="row3" value={this.state.row3} onChange={this.onChange} style={{ width: "100px" }}/></td>

                                            </tr>
                                            <tr>
                                                <td style={{ paddingLeft: "10px" }}>{this.props.row4Text}</td>
                                                <td>% <input type="text" id="row4" name="row4" value={this.state.row4} onChange={this.onChange} style={{ width: "100px" }}/></td>

                                            </tr>
                                            <tr>
                                                <th style={{ paddingLeft: "10px" }}>Total Anually</th>

                                                <th>    <NumberFormat
                                                    value={this.state.row1 * this.state.row2 * this.state.row3 * (this.state.row4/100.0) * this.state.multi}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    renderText={(value, props) => <div {...props}>{value}</div>}
                                                /></th>

                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(withRouter(Calculator));